import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

const List = styled.ul`
  list-style-type: none;
  text-align: center;
  padding: 0;
  margin: 0;
  li {
    font-size: smaller;
    font-weight: bold;
    width: 100%;
  }
`

const AdBlock = styled.section`
  margin: 30px;
  border-style: dotted;
  border-radius: 6px;
  padding: 20px;
  a {
    width: 100%;
    display: block;
    text-decoration: none;
    color: #00a;
  }
`

const ContactButton = styled.span`
    font-size: large;
    background-image: linear-gradient(#2e64ba, #1f4684);
    color: white;
    border: 1px solid #2e64ba;
    padding: 5px 10px;
    border-radius: 3px;
    font-family: helvetica, arial, sans;
    filter: drop-shadow(5px 10px 4px #333);
`;

const AdCardano = () => <AdBlock>
    <Link to="/contact">
      <List>
        <li>Are you looking for a way to build your dApp on Cardano?</li>
        <li>Do you need someone to kickstart your next product using Plutus?</li>
        <li><ContactButton>Contact Javier Casas</ContactButton></li>
      </List>
    </Link>
  </AdBlock>

AdCardano.propTypes = {
}

export default AdCardano
