import React from 'react'
import { graphql } from 'gatsby'
import Blogpost from './blogpost'
import AdCardano from './ad-cardano'

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds our post data
  const { frontmatter, html } = markdownRemark
  return (
    <Blogpost
      disqusTitle={frontmatter.title}
      disqusCategoryId={'12345'}
      disqusUrl={frontmatter.path}
      disqusIdentifier={frontmatter.path.replace(/[/-]/, "")}
      metaDescription={frontmatter.metaDescription}
      metaKeywords={frontmatter.metaKeywords}
      metaUrl={frontmatter.path}
      title={frontmatter.title}
    >
      <div className="blog-post-container">
        <div className="blog-post">
          <header>
            <h1>{frontmatter.title}</h1>
          </header>
          {/*<h2>{frontmatter.date}</h2>*/}
          <div
            className="blog-post-content markdown"
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </div>
      </div>
      { frontmatter.adType === "cardano"
        ? <AdCardano />
        : null
      }
    </Blogpost>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        metaDescription
        metaKeywords
        adType
      }
    }
  }
`
